@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap');

:root {
    font-size: 62.5%;
    --white-smoky: #f2f2f2;
    --yellow-daisy: #ffc500;
    --yellow-daisy-trans: #ffc50080;
    --black-ink: #161616;

    --num-col: 1;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0;
    padding: 0;
    
    font-family: 'Open Sans', 'Zen Kaku Gothic New', 'Noto Sans KR', sans-serif;
    font-weight: 300;

    overflow-x: hidden;
}

::selection {
    background: var(--yellow-daisy-trans);
    color: var(--black-ink);
 }